<template>
	<Transition
		@before-enter="beforeEnter"
		@after-enter="afterEnter"
		@before-leave="beforeLeave"
	>
		<div
			v-if="showIf"
			class="content"
			ref="contentRef"
		>
			<slot></slot>
		</div>
	</Transition>
</template>

<script lang="ts" setup>
/**
 * Usage: Use instead of vue transition element to expand element to height auto.
 * Pass prop :show-if to TransitionAutoHeight instead of v-if on child element.
 * The v-if is set directly on the TransitionAutoHeight component.
 * This ensures that padding and margins are expanded correctly.
 */
defineProps<{
	showIf: boolean;
}>();

const observer = ref<MutationObserver | null>(null);
const contentRef = ref<HTMLElement | null>(null);

const getAutoHeight = (el: HTMLElement) => {
	const clone = el.cloneNode(true) as HTMLElement;
	clone.style.height = "auto";
	clone.style.visibility = "hidden";
	clone.style.pointerEvents = "none";
	el.parentNode?.appendChild(clone);
	const autoHeight = clone.scrollHeight;
	el.parentNode?.removeChild(clone);
	return autoHeight;
};

const expandAndShowElement = (el: HTMLElement) => {
	el.style.height = getAutoHeight(el) + "px";
	el.style.opacity = "1";
	if (!observer.value) {
		observer.value = new MutationObserver(() => {
			expandAndShowElement(contentRef.value);
		});
		observer.value.observe(contentRef.value, { childList: true, subtree: true });
	}
};

const hideElement = (el: HTMLElement) => {
	el.style.height = "0";
	el.style.opacity = "0";
};

onMounted(() => {
	if (contentRef.value) {
		expandAndShowElement(contentRef.value);
	}
});

const beforeEnter = (el: HTMLElement) => {
	hideElement(el);
};

const afterEnter = (el: HTMLElement) => {
	expandAndShowElement(el);
};

const beforeLeave = (el: HTMLElement) => {
	hideElement(el);
};

onBeforeUnmount(() => {
	if (observer.value) observer.value.disconnect();
});
</script>

<style lang="scss" scoped>
.content {
	transition:
		height 300ms cubic-bezier(0.8, 0, 0.2, 1),
		opacity 300ms cubic-bezier(0.8, 0, 0.2, 1);
}
</style>
